import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const EditUnits = () => {
  const [inputStates, setInputStates] = React.useState({});
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const params = useParams();
  const [filteredSymbolList, setFilteredSymbolList] = React.useState([]);
  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    if (params.id) {
      axiosWithToken
        .patch(url + `unit/${params.id}`, inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Unit edited successfully!");
        })
        .catch((error) => {
          toast.error("Something went wrong!");
        });
    } else {
      axiosWithToken
        .post(url + "unit", inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Unit created successfully!");
        })
        .catch((error) => {
          toast.error("Something went wrong!");
        });
    }
  };

  const fetchLabMaster = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `unit/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];
        let newData = { ...inputStates, ...dataObject };
        setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      });
  };

  const refresh = () => {
    window.location.reload(false);
  };
  console.log(params.id);
  const getsymbolList = () => {
    axiosWithToken
      .get(`https://labcity.bfiinfotech.co.in/api/predefineSymbols`)
      .then((res) => {
        const predefinedSymbols = res.data;
        if (params.id) {
          setFilteredSymbolList(predefinedSymbols);
        } else {
          axiosWithToken
            .get(BASE_URL + "unit")
            .then((response) => {
              const existingUnits = response.data.map((unit) => unit.unit);
              const filteredSymbols = predefinedSymbols.filter(
                (symbol) => !existingUnits.includes(symbol.symbol)
              );
              setFilteredSymbolList(filteredSymbols);
            })
            .catch((error) => {
              toast.error("Failed to fetch existing units");
            });
        }
      });
  };

  useEffect(() => {
    getsymbolList();
    if (params.id) fetchLabMaster();
  }, [params.id]);

  return (
    <div>
      <div>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Create/Update Units
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                size="small"
                disabled={params.id}
                value={
                  filteredSymbolList.find(
                    (s) => s.symbol === inputStates.unit
                  ) || null
                }
                options={filteredSymbolList}
                getOptionLabel={(option) => `${option.name} (${option.symbol})`}
                renderInput={(params) => (
                  <TextField {...params} label="Symbol *" />
                )}
                onChange={(event, newValue) => {
                  updateInputObject("unit", newValue ? newValue.symbol : "");
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <span
                      style={{
                        color: option.type === "custom" ? "blue" : "black",
                      }}
                    >
                      {option.name} ({option.symbol})
                    </span>
                  </li>
                )}
              />
            </Grid>
          </Grid>
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
              size="small"
              sx={{ m: 0, ml: "auto", px: 4 }}
              onClick={handleSubmit}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
        </Paper>
      </div>
    </div>
  );
};

export default EditUnits;
