import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Grid,
  useMediaQuery,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ExcelDownload from "../../utils/components/excelDownload";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "urlNumber_cr",
    label: "ULR Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "certificateNo_cr",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName_i",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "labName_lm",
    label: "Lab Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "plantLocation_pm",
    label: "Plant Location",
    align: "left",
    minWidth: 40,
  },

  {
    id: "identificationNo_im",
    label: "Identification Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "serialNumber_im",
    label: "Serial Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentRange_im",
    label: "Ranges",
    align: "left",
    minWidth: 40,
  },
  {
    id: "leastCount_im",
    label: "Least Count",
    align: "left",
    minWidth: 40,
  },
  {
    id: "accuracy_im",
    label: "Accuracy",
    minWidth: 40,
    align: "left",
  },
  {
    id: "make_im",
    label: "Make",
    align: "left",
    minWidth: 40,
  },
  {
    id: "modelNo_im",
    label: "Model Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "lastCalibrationDate_cr",
    label: "Calibration Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "calibrationDueDate_cr",
    label: "DUE Date",
    align: "left",
    minWidth: 40,
  },
];

const notNeededColumn = ["id", "status", "instrumentId"];

const SchedulerList = () => {
  const [page, setPage] = React.useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState(
    sessionStorage.getItem("searchBy") || "Instrument Name"
  );
  const [searchKey, setSearchKey] = React.useState(
    sessionStorage.getItem("searchKey") || ""
  );
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [searched, setSearched] = React.useState(false);
  const navigate = useNavigate();
  const urlPath = useLocation();

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Instrument Name": "i.name",
        "Certificate Number": "cr.certificateNumber",
        "ULR Number": "cr.urlNumber",
        "Plant Location": "pm.location",
        "Lab Name": "lm.name",
        "Identification Number": "im.identificationNumber",
        "Serial Number": "im.serialNumber",
      }[searchBy];
      whereConditions = `${
        to && from ? `where` : `and`
      } ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` cr.dueDate BETWEEN  '${moment(from).format(
        "YYYY-MM-DD"
      )}' AND '${moment(to).format("YYYY-MM-DD")}' `;
    }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT cr.id AS calibrationId_cr, cr.dueDate AS calibrationDueDate_cr, cr.calibrationDate AS lastCalibrationDate_cr, cr.certificateNumber AS certificateNo_cr, cr.urlNumber AS urlNumber_cr, i.name AS instrumentName_i, pm.location AS plantLocation_pm, lm.name AS labName_lm, im.make AS make_im, im.modelNo AS modelNo_im, im.serialNumber AS serialNumber_im, im.identificationNumber AS identificationNo_im, REPLACE(REPLACE(REPLACE(im.instrumentRange, '#', ''), '||', ','), '|', ' to ') AS instrumentRange_im, REPLACE(REPLACE(REPLACE(im.leastCount, '#', ''), '||', ','), '|', ' to ') AS leastCount_im, REPLACE(REPLACE(REPLACE(im.accuracy, '#', ''), '||', ','), '|', ' to ') AS accuracy_im FROM calibration_register AS cr LEFT JOIN instrument_master AS im ON cr.instrumentId = im.id LEFT JOIN instrument AS i ON im.instrumentId = i.id LEFT JOIN plant_master AS pm ON im.plantId = pm.id LEFT JOIN lab_master AS lm ON im.labId = lm.id ${to && from ? "" : `WHERE cr.dueDate IS NOT NULL AND DATE(cr.dueDate) <= CURDATE()`} ${whereConditions} ORDER BY cr.id DESC ${pagination_settings}`,
    };
    

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM calibration_register AS cr LEFT JOIN instrument_master AS im ON cr.instrumentId = im.id LEFT JOIN instrument AS i ON im.instrumentId = i.id LEFT JOIN plant_master AS pm ON im.plantId = pm.id LEFT JOIN lab_master AS lm ON im.labId = lm.id ${to && from ? "" : `WHERE cr.dueDate IS NOT NULL AND DATE(cr.dueDate) <= CURDATE()`} ${whereConditions} ORDER BY cr.id DESC`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
        getTotalRows();
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if (storedSearchBy) {
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    fetchFinalData();
  };

  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched");
    sessionStorage.removeItem("currentPath");
    setSearchBy("Instrument Name");
    setSearchKey("");
    setSearched(false);
    setTo(null);
    setFrom(null);
    // navigate("/instrumentMasterList");
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `vendor_master/${id}`)
      .then((res) => {
        toast("Vendor is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  // useEffect(() => {
  //   getTotalRows();
  // }, []);

  useEffect(() => {
    if (searched == false) {
      fetchFinalData();
      getTotalRows();
    }
  }, [searched]);

  useEffect(() => {
    fetchFinalData();
  }, [rowsPerPage, page]);

  const actions = [
    // {
    //   id: "delete",
    //   tooltip: "Delete Vendor",
    //   icon: <DeleteIcon style={{ color: "#dc3545" }} />,
    //   handler: (row)  => window.confirm('Are you sure you want to delete this?') && handleDelete(row?.id),
    // },
  ];

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 1, px: 1, py: 1 }}>
        <Grid
          container
          spacing={2}
          justifyContent={"flex-start"}
          alignItems="center"
        >
          <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setFrom(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setTo(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
              }}
              value={searchBy}
              size="small"
              id="combo-box-demo"
              options={
                _userType != 3
                  ? [
                      { key: "instrument.name", label: "Instrument Name" },
                      {
                        key: "calibration_register.certificateNumber",
                        label: "Certificate Number",
                      },
                      {
                        key: "calibration_register.urlNumber",
                        label: "ULR Number",
                      },
                      { key: "plant_master.location", label: "Plant Location" },
                      { key: "lab_master.name", label: "Lab Name" },
                      {
                        key: "identificationNo",
                        label: "Identification Number",
                      },
                      {
                        key: "instrument_master.serialNumber",
                        label: "Serial Number",
                      },
                    ]
                  : [{ key: "instrument.name", label: "Instrument Name" }]
              }
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                setSearched(false);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              inputProps={{
                autoComplete: "off",
              }}
              value={searchKey}
              variant="outlined"
              onChange={(e) => {
                setSearchKey(e.target.value);
                setSearched(false);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={0.7} lg={0.7} textAlign={"left"}>
            <Button
              variant="contained"
              size="small"
              style={{ height: "40px" }}
              color={searched ? "error" : "primary"}
              onClick={() => {
                searched ? clearSearch() : search();
              }}
            >
              {searched ? <RestartAltIcon /> : <SearchIcon />}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2} textAlign={"left"}>
            <ExcelDownload finalData={data} notNeededColumn={notNeededColumn} />
          </Grid>
        </Grid>

        <br />

        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  );
};

export default SchedulerList;
