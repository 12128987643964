import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import InfoIcon from '@mui/icons-material/Info';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link, useLocation, useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import ExcelUpload from "../../utils/components/excelUpload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  ,
  {
    id: "identificationNumber",
    label: "Identification Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentRange",
    label: "Range",
    align: "left",
    minWidth: 40,
  },
  {
    id: "leastCount",
    label: "Least Count",
    align: "left",
    minWidth: 40,
  },
  {
    id: "accuracy",
    label: "Accuracy",
    minWidth: 40,
    align: "left",
  },
  {
    id: "make",
    label: "Make",
    minWidth: 40,
    align: "left",
  },
  {
    id: "modelNo",
    label: "Model",
    minWidth: 40,
    align: "left",
    align: "left",
  },
  {
    id: "vendorName",
    label: "Vendor Name",
    minWidth: 40,
    align: "left",
    align: "left",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const InstrumentMasterList = (props) => {
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState(
    sessionStorage.getItem("searchBy") || "Instrument Name"
  );
  const [searchKey, setSearchKey] = React.useState(
    sessionStorage.getItem("searchKey") || ""
  );
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const _userID = localStorage.getItem("id");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [vendorData, setVendorData] = React.useState([]);
  const [vendorID, setVendorID] = React.useState(0);
  const userType = localStorage.getItem("type");
  const _vendorId = localStorage.getItem("vendorId");
  const _plantIds = localStorage.getItem("plantId");
  const [searched, setSearched] = React.useState(false);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";


    if (props?.isCount) {
      whereConditions +=
        whereConditions.length > 0
          ? `and  approvedby is null`
          : `where approvedby is null`;
    }

    if (props?.isAssign) {
      whereConditions +=
        whereConditions.length > 0
          ? `AND NOT EXISTS (SELECT 1 FROM calibration_register WHERE calibration_register.instrumentId = instrument_master.instrumentId AND calibration_register.identificationNumber = instrument_master.identificationNumber)`
          : `WHERE NOT EXISTS (SELECT 1 FROM calibration_register WHERE calibration_register.instrumentId = instrument_master.instrumentId AND calibration_register.identificationNumber = instrument_master.identificationNumber)`;
    }

    
    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Instrument Name": "instrument.name",
        "Identification Number": "identificationNumber",
        "Serial Number": "serialNumber",
        Model: "modelNo",
      }[searchBy];
      whereConditions = `${
        props?.isCount ? `AND` : `WHERE`
      } ${colName} like '%${searchKey}%'`;
    }

    if (_userType == 3) {
      whereConditions +=
        whereConditions.length > 0
          ? `and instrument_master.assignedUser = ${_vendorId}`
          : `where instrument_master.assignedUser = ${_vendorId}`;
    }

    if (_userType == 6) {
      whereConditions +=
        whereConditions.length > 0
          ? ` and instrument_master.plantId IN (${_plantIds})`
          : ` where instrument_master.plantId IN (${_plantIds})`;
      // whereConditions +=
      //   whereConditions.length > 0
      //     ? " AND cert.status = 1"
      //     : "AND cert.status = 1";
    }
    

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT instrument_master.*, instrument.name AS instrumentName, REPLACE(REPLACE(REPLACE(instrumentRange, '#', ''), '||', ','), '|', ' to ') AS instrumentRange, REPLACE(REPLACE(REPLACE(leastCount, '#', ''), '||', ','), '|', ' to ') AS leastCount, REPLACE(REPLACE(REPLACE(accuracy, '#', ''), '||', ','), '|', ' to ') AS accuracy, vendor_master.name AS vendorName, users.userName AS approvedByUserName FROM instrument_master LEFT JOIN instrument ON instrument_master.instrumentId = instrument.id LEFT JOIN vendor_master ON instrument_master.assignedUser = vendor_master.id LEFT JOIN users ON instrument_master.approvedby = users.id ${whereConditions}  ORDER BY instrument_master.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM instrument_master LEFT JOIN instrument ON instrument_master.instrumentId = instrument.id LEFT JOIN vendor_master ON instrument_master.assignedUser = vendor_master.id LEFT JOIN users ON instrument_master.approvedby = users.id ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if (storedSearchBy) {
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    fetchFinalData();
    getTotalRows();
  };

  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched");
    sessionStorage.removeItem("currentPath");
    setSearchBy("Instrument Name");
    setSearchKey("");
    setSearched(false);
    navigate("/instrumentMasterList");
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `instrument_master/${id}`)
      .then((res) => {
        toast("Instrument is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const updateAssignedUser = () => {

    const approvedIds = [];
    const unapprovedData = [];

    selectedRows.forEach((id) => {
      const item = data.find((d) => d.id === id);
      if (item) {
        if (item.approvedby !== null) {
          approvedIds.push(id);
        } else {
          unapprovedData.push({
            id: item.id,
            approvedby: item.approvedby,
            instrumentName: item.instrumentName,
            identificationNumber: item.identificationNumber,
          });
        }
      }
    });

    const instrumentMessages = unapprovedData
      .map((item) => {
        return `${item.instrumentName} (${item.identificationNumber})`;
      })
      .join(", ");

      if (instrumentMessages) {
        const alertMessage = `${instrumentMessages} This instrument is Not approved.`;
      
        // Trigger the alert only if instrumentMessages has values
        if (!window.confirm(alertMessage)) {
          // User clicked "Cancel"
          return;
        }
      }


    let SQL_Query = {
      query: `UPDATE instrument_master SET assignedUser = ${vendorID} WHERE id IN (${approvedIds?.join(
        ","
      )})`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, SQL_Query)
      .then((response) => {
        setTimeout(refresh, 500);
        toast("Instrument Assigned successfully !");
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  };

  const approveInstruments = () => {
    const approvedData = [];
    const unapprovedIds = [];

    selectedRows.forEach((id) => {
      const item = data.find((d) => d.id === id);
      if (item) {
        if (item.approvedby !== null) {
          approvedData.push({
            id: item.id,
            approvedby: item.approvedby,
            instrumentName: item.instrumentName,
            identificationNumber: item.identificationNumber,
          });
        } else {
          unapprovedIds.push(id);
        }
      }
    });

    const instrumentMessages = approvedData
      .map((item) => {
        return `${item.instrumentName} (${item.identificationNumber})`;
      })
      .join(", ");

      if (instrumentMessages) {
        const alertMessage = `${instrumentMessages} This instrument is already approved.`;
      
        // Trigger the alert only if instrumentMessages has values
        if (!window.confirm(alertMessage)) {
          // User clicked "Cancel"
          return;
        }
      }

    let SQL_Query = {
      query: `UPDATE instrument_master SET approvedby = ${_userID} WHERE id IN (${unapprovedIds?.join(
        ","
      )})`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQL_Query)
      .then((response) => {
        setTimeout(refresh, 500);
        toast("Instrument approve successfully !");
      })
      .catch((err) => {
        console.log("Instrument approve error: ", err);
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (searched == false) {
      fetchFinalData();
      getTotalRows();
    }
  }, [searched]);

  useEffect(() => {
    fetchFinalData();
    getTotalRows();
  }, [rowsPerPage, page]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit Instrument",
      link: true,
      linkTo: (id) => `/editInstrumentMaster/${id}`,
      icon: <PreviewIcon />,
    },
    {
      id: "info",
      tooltip: (row) => `Approved By: ${row.approvedByUserName}`,
      icon: <InfoIcon />,
      handler: (row) => {
        alert(
          row.approvedByUserName
            ? `This instrument was approved by ${row.approvedByUserName}`
            : "This instrument is not approved yet"
        );
      },
    },
    {
      id: "delete",
      tooltip: "Delete Instrument",
      disabled: !(editAccess?.includes(2) || editAccess?.includes(0))
        ? true
        : false,
      icon: (
        <DeleteIcon
          style={{
            color: !(editAccess?.includes(2) || editAccess?.includes(0))
              ? "lightgray"
              : "#dc3545",
          }}
        />
      ),
      handler: (row) =>
        window.confirm("Are you sure you want to delete this?") &&
        handleDelete(row?.id),
    },
  ];

  const ExcelModal = () => {
    const [open, setOpen] = React.useState(false);
    const [excelArray, setExcelArray] = React.useState([]);
    const [instruments, setInstruments] = React.useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const fetchInstrumentsData = () => {
      let url = BASE_URL;
      axiosWithToken
        .get(url + "instrument?_fields=id,name")
        .then((res) => {
          setInstruments(res.data);
        })
        .catch((err) => console.log("instruments data error: ", err));
    };

    // Arrays to hold rejected objects and remaining objects
    const rejectedData = [];
    const remainingData = [];

    // Check for clientId and instrumentId
    for (const excelObj of excelArray) {
      const foundInstrument = instruments.find(
        (instrument) => instrument.id === excelObj.instrumentId
      );

      if (!foundInstrument) {
        const rejectedObj = {
          ...excelObj,
          rejectionStatus: "Instrument is not found in Instrument database",
        };
        rejectedData.push(rejectedObj);
      } else {
        remainingData.push(excelObj);
      }
    }

    var refresh = () => {
      window.location.reload(false);
    };

    const handleSubmit = () => {
      axiosWithToken
        .post(BASE_URL + "instrument_master/bulk", remainingData)
        .then((res) => {
          setTimeout(refresh, 500);
          toast.success("Excel data uploaded Successfully!");
        })
        .catch((error) => {
          toast.error("Something Went Wrong In instrument_master!");
        });
    };

    const fetchVendor_master = () => {
      let url = BASE_URL;
      axiosWithToken
        .get(url + `vendor_master?_fields=id,name`)
        .then((res) => {
          let dataObject = res.data;
          setVendorData(dataObject);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    };

    useEffect(() => {
      fetchInstrumentsData();
      fetchVendor_master();
    }, []);
    return (
      <div>
        {selectedRows.length == 0 ? (
          <Button
            id="ducMaster_list_uploadexcel"
            variant="contained"
            // style={{marginLeft:"15px"}}
            // size="small"
            onClick={handleOpen}
          >
            Upload Excel File
          </Button>
        ) : null}
    
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              sx={{ textAlign: "center" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Upload Excel for Instrument Master
            </Typography>

            <Typography
              sx={{ textAlign: "right", fontSize: "15px" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {`Rejected Data:${rejectedData.length}, Accepted Data:${remainingData.length}`}
            </Typography>

            <Box style={{ margin: "auto" }}>
              <ExcelUpload
                setExcelArray={setExcelArray}
                buttonName={"Select Excel File"}
                isDUCMaster={true}
              />
            </Box>

            <Typography
              sx={{ textAlign: "center", margin: "15px 0px" }}
              id="modal-modal-title"
              variant="h6"
              component="h6"
            >
              Rejected Excel Data
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Rejected Data Table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.</TableCell>
                    <TableCell>Client ID</TableCell>
                    <TableCell>Instrument ID</TableCell>
                    <TableCell>DUCID</TableCell>
                    <TableCell>Rejection Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rejectedData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.clientId}</TableCell>
                      <TableCell>{row.instrumentId}</TableCell>
                      <TableCell>{row.DUCID}</TableCell>
                      <TableCell>{row.rejectionStatus}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              style={{
                margin: "20px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                component="span"
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                component="span"
                disabled={!remainingData.length > 0}
                onClick={handleSubmit}
              >
                continue
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 1, px: 1 }}>
        {userType != 3 ? (
          <Toolbar style={{ padding: 0 }}>
            {showComponent("add") && (
              <Button
                variant="contained"
                size="small"
                component={Link}
                to="/editInstrumentMaster"
                // onClick={() => {
                //   setTitle("Master/ add new user");
                // }}
              >
                <b>ADD NEW</b>
              </Button>
            )}
          </Toolbar>
        ) : null}

      <Grid
          container
          spacing={2}
          justifyContent={"flex-start"}
          alignItems="center"
          style={{ padding: "5px 5px" }}
        >
          {selectedRows?.length > 0 && (
            <>
              {/* <Grid item xs={6} sm={6} md={2} lg={2}>
                <Autocomplete
                  size="small"
                  options={vendorData}
                  value={
                    vendorData?.filter((ele) => ele?.id == vendorID)?.[0] ||
                    null
                  }
                  getOptionLabel={(option) => `${option.id}, ${option.name}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a vendor"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) => {
                    setVendorID(value?.id);
                    setSearched(false);
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={2} md={2} lg={2}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ m: 0 }}
                  // style={{ marginLeft: "10px" }}
                  onClick={() => {
                    updateAssignedUser();
                  }}
                >
                  Assign Instrument
                </Button>
              </Grid> */}
              {userType == 1 || userType == 2 || userType == 4 ? (
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ m: 0 }}
                    // style={{ marginLeft: "10px" }}
                    onClick={() => {
                      approveInstruments();
                    }}
                  >
                    Approve Instrument
                  </Button>
                </Grid>
              ) : null}
            </>
          )}

          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
              }}
              size="small"
              id="combo-box-demo"
              value={searchBy}
              options={
                _userType != 3
                  ? [
                      { key: "name", label: "Instrument Name" },
                      {
                        key: "identificationNumber",
                        label: "Identification Number",
                      },
                      { key: "serialNumber", label: "Serial Number" },
                      { key: "modelNo", label: "Model" },
                    ]
                  : [
                      { key: "name", label: "Instrument Name" },
                      { key: "modelNo", label: "Model" },
                    ]
              }
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                setSearched(false);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              inputProps={{
                autoComplete: "off",
              }}
              variant="outlined"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </Grid>
          <Grid item xs={5} sm={5} md={0.5} lg={0.5}>
            <Button
              variant="contained"
              size="small"
              style={{ height: "40px" }}
              color={searched ? "error" : "primary"}
              onClick={() => {
                searched ? clearSearch() : search();
              }}
            >
              {searched ? <RestartAltIcon /> : <SearchIcon />}
            </Button>
          </Grid>
          {_userType != 3  && 
           <Grid item xs={7} sm={7} md={2.7} lg={2.7}>
           {ExcelModal()}
         </Grid>
          }
         
        </Grid>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
          multipleSelector={_userType != 3 ? true : false}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          isInstrumentMaster={true}
        />
      </TableContainer>
    </div>
  );
};

export default InstrumentMasterList;
