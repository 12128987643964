import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { Box, LinearProgress, Tooltip } from "@mui/material";
import { Form, Button, Card } from "react-bootstrap";
import Modal from "@mui/material/Modal";
import logo from "../image/logo.png";
import "react-toastify/dist/ReactToastify.css";

import "./Login.css";

//API handling components
import { BASE_URL } from "./../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 312,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "20px",
  textAlign: "center",
  borderRadius: "25px",
  display: "flex",
  flexDirection: "column",
};

const data = { userName: "admin", password: "admin" };

function Login(props) {
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const configure = (_id, _userName, type, modules = "", clientId = 0, editAccess, vendorId, plantId) => {
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("id", _id);
    localStorage.setItem("clientId", clientId);
    localStorage.setItem("userName", _userName);
    localStorage.setItem("type", type);
    localStorage.setItem("modules", modules);
    localStorage.setItem("editAccess", editAccess);
    localStorage.setItem("vendorId", vendorId);
    localStorage.setItem("plantId", plantId);


    toast.success("Login Successful!");

    props.setIsLoggedIn(true);
    navigate("/dashboard", { replace: true });
    // type == 3 && navigate("/clientDashboard", { replace: true });
  };

  const renderModal = () => {
    return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={modalStyle}>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
            }}
          >
            <span>Kindly contact your admin to reset your credentials!</span>
          </div>
        </Box>
      </Modal>
    );
  };

  const handleSubmit = (e) => {
    axiosWithToken
      .post(BASE_URL + `login`, { userName: userName, password: password })
      .then((res) => {
        // console.log("res.data.token",res.data.token)
        const authToken = res.data.token;
        sessionStorage.setItem("authToken", authToken);
        sessionStorage.setItem("logInUser", res.data?.user?.userName);
        if (res.status === 200) {
          // props.setLoader(false);
       
          configure(
            res.data?.user?.id,
            res.data?.user?.userName,
            res.data?.user?.type,
            res.data?.user?.modules,
            res.data?.user?.clientId,
            res.data?.user?.type !=3 ? res.data?.user?.editAccess : 6,
            res.data?.user?.vendorId,
            res.data?.user?.plantId,

          );
        } else {
          setError("Incorrect Username / password");
          setUserName("");
          setPassword("");
          //props.setLoader(false);
        }
        let falseLoginCount = localStorage.getItem("loginCount");

        if (falseLoginCount > 2) {
          localStorage.setItem("blockTimer", Date.now() + 60000);
        }

        localStorage.setItem("loginCount", falseLoginCount + 1);

        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Incorrect Username / password");
        console.log(error);
        setTimeout(() => {
          window.location.reload(); 
      }, 700);
      });
  };

  return (
    <>
      <div class="limiter">
        <div class="container-login100">
          <div class="wrap-login100">
            <div class="login100-pic js-tilt" data-tilt>
              <img className="" src={logo} alt="IMG" />
            </div>

            <div class="login100-form validate-form">
              <span class="login100-form-title">Login</span>

              <div
                class="wrap-input100 validate-input"
                data-validate="Valid email is required: ex@abc.xyz"
              >
                <input
                  class="input100"
                  type="text"
                  name="email"
                  placeholder="Username"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <span class="focus-input100"></span>
                <span class="symbol-input100">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </span>
              </div>

              <div
                class="wrap-input100 validate-input"
                data-validate="Password is required"
              >
                <input
                  class="input100"
                  type="password"
                  name="pass"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span class="focus-input100"></span>
                <span class="symbol-input100">
                  <i class="fa fa-lock" aria-hidden="true"></i>
                </span>
              </div>
              <span style={{ color: "red" }}>{error}</span>

              <div class="container-login100-form-btn">
                <button
                  class="login100-form-btn"
                  onClick={() => {
                    // props.setLoader(true);
                    setIsLoading(true);
                    handleSubmit();
                  }}
                  disabled={
                    isLoading || localStorage.getItem("blockTimer") > Date.now()
                      ? true
                      : false
                  }
                  style={{
                    opacity: isLoading ? 0.5 : 1,
                    backgroundColor: isLoading ? "#ccc" : "#57b846",
                  }}
                >
                  Login
                </button>
              </div>

              <div class="text-center p-t-12">
                <span class="txt1">Forgot</span>
                <button
                  class="txt2"
                  style={{ backgroundColor: "#0000" }}
                  onClick={(e) => handleOpen(true)}
                >
                  Username / Password?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderModal()}
    </>
  );
}

export default Login;
