import React, { useMemo } from "react";
import {
  Box,
  Button,
  Input,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../global";
import axiosWithToken from "./axiosTokenConfig";

const ImageWithTable = ({ inputStates, setInputStates, label, stateKey }) => {
  const imageValue = inputStates?.[stateKey] || [];

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);

    try {
      const res = await axiosWithToken.post(
        BASE_URL + "fileUpload/fileUpload.php",
        data
      );
      if (res.status === 200) {
        // Update only the specific stateKey, preserve other keys
        setInputStates((prevState) => ({
          ...prevState,
          [stateKey]: [...imageValue, res.data],
        }));
        toast.success("File Uploaded Successfully!", {
          position: "top-right",
          autoClose: 3000,
          theme: "light",
        });
      }
    } catch (err) {
      if (err.message !== "request_aborted") {
        toast.error(
          <h6>
            <u>Error: {err.message}</u>
          </h6>
        );
      }
    }
  };

  const handleDelete = (urlToDelete) => {
    const updatedList = imageValue.filter((url) => url !== urlToDelete);
    // Update only the specific stateKey, preserve other keys
    setInputStates((prevState) => ({
      ...prevState,
      [stateKey]: updatedList,
    }));
  };

  const renderTableRows = useMemo(() => {
    return imageValue.map((url, index) => (
      <TableRow key={index}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{url?.split("__")[1]}</TableCell>
        <TableCell>
          <Button component={Link} to={url} target="_blank">
            <PreviewIcon />
          </Button>
          <DeleteIcon
            style={{ color: "#dc3545" }}
            onClick={() => {
              const confirmation = window.confirm(
                "Are you sure you want to delete this?"
              );
              if (confirmation) {
                handleDelete(url);
              }
            }}
          />
        </TableCell>
      </TableRow>
    ));
  }, [imageValue]);

  return (
    <div>
      <h4 style={{ textAlign: "left", marginBottom: "20px" }}>{label}</h4>
      <div style={{ textAlign: "left", marginBottom: "20px" }}>
        <Input
          id="file-upload-input1"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        <label htmlFor="file-upload-input1">
          <Button variant="contained" component="span" size="small">
            {`Upload ${stateKey == "workSopDoc" ? `Doc` : `Image`}`}
          </Button>
        </label>
      </div>

      {imageValue.length > 0 && (
        <TableContainer>
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#ddd",
                  textAlign: "center",
                }}
              >
                <TableCell>Sr.No</TableCell>
                <TableCell>File Name</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows}</TableBody>
          </Table>
        </TableContainer>
      )}

      <ToastContainer />
    </div>
  );
};

export default ImageWithTable;
