import { Autocomplete, Button, Grid, Paper, TextField, Toolbar, Typography } from '@mui/material'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from 'react'
import { BASE_URL } from "../../global";
import axiosWithToken from '../../utils/components/axiosTokenConfig';




const EditPlantMaster = () => {
  const [inputStates, setInputStates] = React.useState({});
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const params = useParams();

  const updateInputObject = (key, value, q) => {
      let newInputObject = {
        ...inputStates,
      };    
      newInputObject[key] = value;    
      setInputStates({ ...newInputObject });
    };

    const handleSubmit =()=>{
      console.log("inputeStates",inputStates)

      let url = BASE_URL;
      if (params.id) {
        axiosWithToken
          .patch(url + `plant_master/${params.id}`, inputStates)
          .then((res) => {
            setTimeout(refresh, 500);
            toast("Plant_master edit successfully !");
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
  
      } else {
        axiosWithToken
          .post(url + "plant_master", inputStates)
          .then((res) => {
            setTimeout(refresh, 500);
            toast("Plant_master created successfully !");
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
  
      }

    }

    const fetchPlant_master = (inpt) => {
      let url = BASE_URL;
      axiosWithToken
        .get(url + `plant_master/${params.id}`)
        .then((res) => {
          let dataObject = res.data[0];
  
          let newData = {
            ...inputStates,
            ...dataObject,
           
          };
            dataObject && setInputStates(newData);
         
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    };

    var refresh = () => {
      window.location.reload(false);
    };

useEffect(()=>{
 if(params.id)fetchPlant_master()
},[params.id])

return (
  <div>
          <div>
   <Paper sx={{ mt: 2, p: 2 }}>
   <Typography variant="h6" component="h6" style={{ float: "left" }}>
      Create/Update Plant Master
    </Typography>
    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
     
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <TextField
          id="outlined-basic"
          label="Location  *"
          size="small"
          fullWidth
          inputProps={{
              autoComplete: 'off',
            }}
          variant="outlined"
          value={inputStates.location || ""}
          onChange={(e) => {
            updateInputObject("location", e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <TextField
          id="outlined-basic"
          label="Sub Location *"
          size="small"
          value={inputStates.subLocation || ""}
          fullWidth
          inputProps={{
              autoComplete: 'off',
            }}
          variant="outlined"
          onChange={(e) => {
            updateInputObject("subLocation", e.target.value);
          }}
        />
      </Grid>

    </Grid>
    <Toolbar style={{ padding: "0px", overflow: "auto" }}>
    <Button
          variant="contained"
          disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
          size="small"
          sx={{ m: 0, ml: "auto", px: 4 }}
          onClick={() => {
               handleSubmit()             
          }}
        >
      {params.id ? "Update":"Save"}
        </Button>
    </Toolbar>
   </Paper>
  </div>
  </div>
)
}

export default EditPlantMaster