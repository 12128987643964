import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Grid,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from "@mui/icons-material/History";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ExcelDownload from "../../utils/components/excelDownload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "identificationNo",
    label: "Identification Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentRange",
    label: "Ranges",
    align: "left",
    minWidth: 40,
  },
  {
    id: "leastCount",
    label: "Least Count",
    align: "left",
    minWidth: 40,
  },
  {
    id: "accuracy",
    label: "Accuracy",
    minWidth: 40,
    align: "left",
  },
  // {
  //   id: "make",
  //   label: "Make",
  //   minWidth: 40,
  //   align: "left",
  // },
  {
    id: "modelNo",
    label: "Model Number",
    minWidth: 40,
    align: "left",
  },
  {
    id: "certificateNumber",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },
  // {
  //   id: "urlNumber",
  //   label: "URL Number",
  //   align: "left",
  //   minWidth: 40,
  // },
  {
    id: "calibrationDate",
    label: "Calibration Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "dueDate",
    label: "DUE Date",
    align: "left",
    minWidth: 40,
  },
];

const notNeededColumn = ["id", "status"];

const CalibrationRegisterList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const urlPath = useLocation();
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Instrument Name");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userID = localStorage.getItem("id");

  const _vendorId = localStorage.getItem("vendorId");
  const _plantIds = localStorage.getItem("plantId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const userType = localStorage.getItem("type");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [searched, setSearched] = React.useState(false);

  console.log("totalRows",totalRows)

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Instrument Name": "instrument.name",
        "Identification Number": "instrument_master.identificationNumber",
        "Serial Number": "instrument_master.serialNumber",
        "Model Number": "instrument_master.modelNo",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` calibration_register.calibrationDate between '${moment(
        from
      ).format("YYYY-MM-DD")}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (_userType == 3) {
      whereConditions +=
        whereConditions.length > 0
          ? `and instrument_master.assignedUser = ${_vendorId}`
          : `where instrument_master.assignedUser = ${_vendorId}`;
      // whereConditions +=
      //   whereConditions.length > 0
      //     ? " AND cert.status = 1"
      //     : "AND cert.status = 1";
    }

    if (_userType == 6) {
      whereConditions +=
        whereConditions.length > 0
          ? ` and instrument_master.plantId IN (${_plantIds})`
          : ` where instrument_master.plantId IN (${_plantIds})`;

    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT calibration_register.*, instrument.name AS instrumentName, instrument_master.make, instrument_master.modelNo, instrument_master.serialNumber, instrument_master.identificationNumber AS identificationNo, REPLACE(REPLACE(REPLACE(instrument_master.instrumentRange, '#', ''), '||', ','), '|', ' to ') AS instrumentRange, REPLACE(REPLACE(REPLACE(instrument_master.leastCount, '#', ''), '||', ','), '|', ' to ') AS leastCount, REPLACE(REPLACE(REPLACE(instrument_master.accuracy, '#', ''), '||', ','), '|', ' to ') AS accuracy FROM calibration_register LEFT JOIN instrument_master ON calibration_register.instrumentId = instrument_master.instrumentId AND calibration_register.identificationNumber = instrument_master.identificationNumber LEFT JOIN instrument ON instrument_master.instrumentId = instrument.id ${whereConditions} ORDER BY calibration_register.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows  FROM calibration_register LEFT JOIN instrument_master ON calibration_register.instrumentId = instrument_master.instrumentId AND calibration_register.identificationNumber = instrument_master.identificationNumber LEFT JOIN instrument ON instrument_master.instrumentId = instrument.id ${whereConditions} `;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);    
    setSearched(true);
    fetchFinalData();
    getTotalRows();
  };

  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched");
    sessionStorage.removeItem("currentPath");
    setSearchBy("Instrument Name");
    setSearchKey("");
    setSearched(false); 
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `calibration_register/${id}`)
      .then((res) => {
        toast("Calibration is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const approveCertificate = () => {
    const approvedData = [];
    const unapprovedIds = [];
    const unapprovedInstrumentIds = [];

    selectedRows.forEach((id) => {
      const item = data.find((d) => d.id === id);
      if (item) {
        if (item.certificateApprove !== null && item.certificateApprove !== 0) {
          approvedData.push({
            id: item.id,
            certificateApprove: item.certificateApprove,
            instrumentName: item.instrumentName,
            identificationNumber: item.identificationNumber,
          });
        } else {
          unapprovedIds.push(id);
          unapprovedInstrumentIds.push(item?.instrumentId)
        }
      }
    });

    const instrumentMessages = approvedData
      .map((item) => {
        return `${item.instrumentName} (${item.identificationNumber})`;
      })
      .join(", ");

      if (instrumentMessages) {
        const alertMessage = `${instrumentMessages} This Certificate is already approved.`;
      
        // Trigger the alert only if CertificateMessages has values
        if (!window.confirm(alertMessage)) {
          // User clicked "Cancel"
          return;
        }
      }


      console.log("unapprovedIds",unapprovedIds)

    let SQL_Query = {
      query: `UPDATE calibration_register SET certificateApprove = ${_userID} WHERE id IN (${unapprovedIds?.join(
        ","
      )})`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, SQL_Query)
      .then((response) => {
        let SQL_Query = {
          query: `UPDATE instrument_master SET assignedUser = null WHERE id IN (${unapprovedInstrumentIds?.join(
            ","
          )})`,
        };
        axiosWithToken.post(BASE_URL + `dynamic`, SQL_Query);

        setTimeout(refresh, 500);
        toast("Certificate approve successfully !");
        
      })
      .catch((err) => {
        console.log("Certificate approve error: ", err);
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (searched==false) {
      fetchFinalData();
      getTotalRows();
    }
  }, [searched]);
  
  useEffect(() => {
    fetchFinalData();
    getTotalRows();
  }, [rowsPerPage,page]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit Calibration",
      link: true,
      linkTo: (id) => `/editCalibrationRegister/${id}`,
      icon: <PreviewIcon />,
    },

    {
      id: "viewCertificate",
      tooltip: "View Certificate",
      icon: <DocumentScannerIcon />,
      handler: (row) => {
        if (row.certificateFilePath) {
          window.open(row.certificateFilePath, "_blank");
        }
      },
    },
    {
      id: "History",
      tooltip: "Calibration History",
      link: true,
      linkTo: (id) => `/calibrationRegisterHistory/${id}`,
      icon: <HistoryIcon />,
    },
    {
      id: "delete",
      tooltip: "Delete Calibration",
      disabled:!(editAccess?.includes(2) || editAccess?.includes(0)) ? true : false,
      icon: (
        <DeleteIcon
          style={{
            color: !(editAccess?.includes(2) || editAccess?.includes(0))
              ? "lightgray"
              : "#dc3545",
          }}
        />
      ),
      handler: (row) =>
        window.confirm("Are you sure you want to delete this?") &&
        handleDelete(row?.id),
    },
  ];

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 1 , px:1 }}>
    
          <Toolbar style={{padding:0}}>
            {showComponent("add") && (
              <Button
                variant="contained"
                size="small"
                component={Link}
                to="/editCalibrationRegister"
                // onClick={() => {
                //   setTitle("Master/ add new user");
                // }}
              >
                <b>ADD NEW</b>
              </Button>
            )}
          </Toolbar>
  

        <Grid
          container
          spacing={2}
          justifyContent={"flex-start"}
          alignItems="center"
          style={{ padding: "5px 5px" }}
        >

{selectedRows?.length > 0 && (
            <>
             
              {userType == 1 || userType == 2 || userType == 4 ? (
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ m: 0 }}
                    // style={{ marginLeft: "10px" }}
                    onClick={() => {
                      approveCertificate();
                    }}
                  >
                    Approve Certificate
                  </Button>
                </Grid>
              ) : null}
            </>
          )}

          <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setFrom(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setTo(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
              }}
              size="small"
              id="combo-box-demo"
              value={searchBy}
              options={
                _userType != 3
                  ? [
                      { key: "instrument.name", label: "Instrument Name" },
                      {
                        key: "instrument_master.identificationNumber",
                        label: "Identification Number",
                      },
                      {
                        key: "instrument_master.serialNumber",
                        label: "Serial Number",
                      },
                      {
                        key: "instrument_master.modelNo",
                        label: "Model Number",
                      },
                    ]
                  : [{ key: "instrument.name", label: "Instrument Name" }]
              }
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                setSearched(false);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              value={searchKey}
              variant="outlined"
              inputProps={{
                autoComplete: 'off',
              }}
              onChange={(e) => {
                setSearchKey(e.target.value);
                setSearched(false);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={0.5} lg={0.5} textAlign={"left"}>
            <Button
              variant="contained"
              size="small"
              sx={{ height:"40px" }}
              color={searched ? "error" : "primary"}
              onClick={() => {
                searched ? clearSearch() : search();
              }}
            >
              {searched ? <RestartAltIcon /> : <SearchIcon />}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <ExcelDownload finalData={data} notNeededColumn={notNeededColumn} />
          </Grid>
        </Grid>

        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
          multipleSelector={_userType != 3 ? true : false}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          isCalibrationRegister={true}
        />
      </TableContainer>
    </div>
  );
};

export default CalibrationRegisterList;
