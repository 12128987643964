import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  Divider,
  Card,
  CardContent,
  Chip,
  Paper,
} from "@mui/material";
import Chart from "./Chart";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BiUserPlus } from "react-icons/bi";
import { FaCalculator } from "react-icons/fa";
import { SlBasket } from "react-icons/sl";
import { ToastContainer, toast } from "react-toastify";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import { BASE_URL } from "./../../global";
import { TbBuildingWarehouse } from "react-icons/tb";
import "./style.css";
import { Link } from "react-router-dom";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import InstrumentMaster from "./instrumentMaster";
import TodaysStdInOut from "./todaysStdInOut";
import TodaysSceduler from "./todayScheduler";

import calImage from "../image/calImage1.jpg";

const ColorArray = [
  {
    bgColor: "linear-gradient(45deg, #4099ff, #3d6ba0)",
    darkBg: "#3d6ba0",
  },
  {
    bgColor: "linear-gradient(45deg, #37ceb0, #338f7c)",
    darkBg: "#338f7c",
  },
  {
    bgColor: "linear-gradient(45deg, #ffb64d, #8f6c3b)",
    darkBg: "#8f6c3b",
  },
  {
    bgColor: "linear-gradient(45deg, #ff5370, #97404f)",
    darkBg: "#97404f",
  },
];
const CustomCard = ({ icon, iconColor, title, count, link }) => {
  const IconComponent = icon; // Dynamically set the icon component

  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <Card
        className="cardContent"
        sx={{
          m: 2,
          p: 1,
          display: "flex",
          border: 1,
          borderColor: "#283593",
        }}
        style={{ height: "125px" }}
      >
        <IconComponent style={{ fontSize: "100px", color: "#283593" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography>{title}</Typography>
            <Typography
              component="div"
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: iconColor,
              }}
            >
              {count}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Link>
  );
};

export default function Dashboard() {
  const userId = localStorage.getItem("id");
  const _vendorId = localStorage.getItem("vendorId");
  const userType = localStorage.getItem("type");
  const theme = useTheme();
  const [approvalRequests, setApprovalRequests] = useState(0);
  const [assignInstruments, setAssignInstruments] = useState(0);
  const [DueInstruments, setDueInstruments] = useState([]);
  const moduleAccess = (localStorage.getItem("modules") || "")
    ?.split(",")
    ?.map((numStr) => Number(numStr))
    .filter((num) => !isNaN(num));
  const [overAll, setOverAll] = useState({
    Instruments: 0,
    Plants: 0,
    Vendors: 0,
    Total_Due: 0,
  });

  const resultArray = Object.entries(overAll).map(([label, count]) => ({
    label,
    count: count < 10 ? `0${count}` : count,
  }));

  /////////////////////////////DashboardData////////////////////////

  const fetchCount = async (table, dateColumn, condition) => {
    try {
      const url = `${BASE_URL}dynamic`;
      const query = `SELECT * FROM ${table} WHERE DATE(${dateColumn}) ${condition}`;
      const response = await axiosWithToken.post(url, { query });
      return response.data.length;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const fetchOverallCount = async (table) => {
    try {
      const url = `${BASE_URL}dynamic`;
      const query = `SELECT * FROM ${table} `;
      const response = await axiosWithToken.post(url, { query });
      return response.data.length;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const fetchDataCounts = async () => {
    // const todaysClientsCount = await fetchCount('clients', 'lastModified', '= CURDATE()');
    // const todaysSrfsCount = await fetchCount('srfs', 'lastModified', '= CURDATE()');
    const overAllInstrument = await fetchOverallCount("instrument_master");
    const overAllPlants = await fetchOverallCount("plant_master");
    const overAllVendors = await fetchOverallCount("vendor_master");
    const overAllDue = await fetchCount(
      "calibration_register",
      "dueDate",
      "<= CURDATE()"
    );

    setOverAll({
      Instruments: overAllInstrument,
      Plants: overAllPlants,
      Vendors: overAllVendors,
      Total_Due: overAllDue,
    });
  };

  const getApprovalRequestCount = () => {
    let query = {
      query: `select count(*) as no_of_rows from instrument_master where  approvedby is null`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, query)
      .then((res) => {
        let resCount = res.data[0].no_of_rows;
        // console.log(res.data[0].no_of_rows)
        setApprovalRequests(resCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAssignInstrumentsCount = () => {
    let query = {
      query: `SELECT COUNT(*) AS no_of_rows FROM instrument_master WHERE assignedUser = ${_vendorId} AND NOT EXISTS (SELECT 1 FROM calibration_register WHERE calibration_register.instrumentId = instrument_master.instrumentId AND calibration_register.identificationNumber = instrument_master.identificationNumber)`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, query)
      .then((res) => {
        let resCount = res.data[0].no_of_rows;
        // console.log(res.data[0].no_of_rows)
        setAssignInstruments(resCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDueInstruments = () => {
    let query = {
      query: `SELECT im.*, i.name AS instrumentName FROM instrument_master im LEFT JOIN instrument i ON im.instrumentId = i.id LEFT JOIN (SELECT iio1.* FROM instrumentInOut iio1 INNER JOIN (SELECT instrumentMasterId, MAX(id) AS maxId FROM instrumentInOut GROUP BY instrumentMasterId) AS max_iio ON iio1.id = max_iio.maxId) iio_max ON im.id = iio_max.instrumentMasterId WHERE im.dueDate < CURDATE() + INTERVAL 1 DAY AND (iio_max.instrumentMasterId IS NULL OR (iio_max.actualReturnDate IS NOT NULL AND DATE(iio_max.actualReturnDate) <= CURRENT_DATE));`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, query)
      .then((res) => {
        setDueInstruments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function transformInputStates(DueInstruments) {
    // Map each ID to a new object with the other data
    return DueInstruments.map((ele) => ({
      instrumentMasterId: ele.id,
      purpose: null,
      outwordDate: new Date().toISOString().split("T")[0],
      expectedReturnDate: null,
      vendorId: ele.assignedUser,
      dispatchMode: null,
      remarks: null,
    }));
  }
 
  const instrumentOut = () => {
    let url = BASE_URL;
    const transformedArray = transformInputStates(DueInstruments);
  
    axiosWithToken
      .post(url + "instrumentInOut/bulk", transformedArray)
      .then((res) => {
        toast.success("All Instruments Out for Calibration");
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        console.error(error);
      });
  };

  let alertShown = false; // Variable to track if the alert was shown

  useEffect(() => {
    if (DueInstruments?.length > 0 && !alertShown) {
      let counter = 1;

      const expiredInstruments = DueInstruments.map(
        (standard) =>
          `${counter++}. ${standard.identificationNumber} (${
            standard.instrumentName
          })`
      );

      const userResponse = window.confirm(
        `Below Instruments are expired:\n${expiredInstruments.join(
          ", \n"
        )}\n\nDo you want to proceed?`
      );
      if (userResponse) {
        instrumentOut(); // Call the function if "OK" is clicked
      }
      alertShown = true; // Mark the alert as shown to prevent it from reappearing
    }
  }, [DueInstruments]);

  useEffect(() => {
    fetchDataCounts();
    getApprovalRequestCount();
    getAssignInstrumentsCount();
    getDueInstruments();
  }, []);

  useEffect(() => {
    if (userType == 3) getAssignInstrumentsCount();
  }, [_vendorId, userType]);

  return (
    <>
      {userType == 3 ? (
        <>
          {assignInstruments != 0 && userType == 3 && (
            <div
              className="shadow-sm p-1 rounded position-fixed"
              style={{ right: "1rem" }}
            >
              <Link to="/instrumentMasterList/assign">
                <button
                  type="button"
                  className="btn btn-warning btn-md"
                  onClick={() => sessionStorage.setItem("searched", "true")}
                >
                  <b>Assign Instruments ({assignInstruments})</b>
                </button>
              </Link>
            </div>
          )}
          <div>
            <img
              src={calImage}
              style={{
                height: "auto",
                maxHeight: "100%",
                maxWidth: "100%",
                width: "95%",
                objectFit: "contain",
              }}
            />
          </div>
        </>
      ) : (
        <div>
          {approvalRequests != 0 &&
            (userType == 1 || userType == 2 || userType == 4) && (
              <div
                className="shadow-sm p-1 rounded position-fixed"
                style={{ right: "1rem" }}
              >
                <Link to="/instrumentMasterList/count">
                  <button
                    type="button"
                    className="btn btn-warning btn-md"
                    onClick={() => sessionStorage.setItem("searched", "true")}
                  >
                    <b>Approval Request ({approvalRequests})</b>
                  </button>
                </Link>
              </div>
            )}
          <Box p={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "16px",
                width: "content-width",
              }}
            >
              <Typography
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
                variant="h6"
                data-text="Overall Data:-"
              >
                Overall Activity:-
              </Typography>
            </div>
            <Box pb={2}>
              <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomCard
                    icon={PrecisionManufacturingOutlinedIcon}
                    iconColor="#283593"
                    title={"Instruments"}
                    count={overAll.Instruments}
                    link="/instrumentMasterList"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomCard
                    icon={TbBuildingWarehouse}
                    iconColor="#283593"
                    title={"Plants"}
                    count={overAll.Plants}
                    link="/plantMasterList"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomCard
                    icon={CardGiftcardOutlinedIcon}
                    iconColor="#283593"
                    title={"Vendors"}
                    count={overAll.Vendors}
                    link="/vendorMasterList"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomCard
                    icon={RotateLeftOutlinedIcon}
                    iconColor="#283593"
                    title={"Total_Due"}
                    count={overAll.Total_Due}
                    link="/schedulerList"
                  />
                </Grid>
              </Grid>
              {/* Top Card */}
              <Grid container spacing={3}>
                {resultArray.map((card, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    {/* <Box
          sx={{
            p: 4,
            position: "relative",
            borderRadius: "5px 5px 0px 0px",
            // background: `${ColorArray[index % 4].bgColor}`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              marginLeft: "-120px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: card.label === "User" ? "black" : "white",
                fontWeight:
                  card.label === "User" ? "bold" : "extrabold",
              }}
            >
              {card.count}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: card.label === "User" ? "black" : "white",
                fontWeight: "medium",
                fontFamily: "press",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                paddingLeft: "120px",
              }}
            >
              {card.label}
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              py: 3,
              color: card.label === "User" ? "gray.400" : "gray.300",
              opacity: 0.5,
              px: 2,
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.1)",
                duration: 500,
              },
            }}
          >
            {card.label === "Clients" ? (
              <BiUserPlus size={80} />
            ) : (
              <TbBuildingWarehouse size={80} color="#fff" />
            )}
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 1,
            gap: 2,
            color: "white",
            background: `${ColorArray[index % 4].darkBg}`,
            borderRadius: "0 0 4px 4px",
            cursor: "pointer",
          }}
        ></Box> */}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          {/* <Divider sx={{ borderTop: "2px solid" }} /> */}
          {/* <Box p={3}>
<div
  style={{
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "16px",
  }}
>
  <Typography
    variant="h6"
    style={{
      color: "black",
      fontWeight: "bold",
    }}
    data-text="Engineer Report:-"
  >
    Daily Activity:-
  </Typography>
</div>{" "}
<Box pb={2}>
  <Grid container spacing={3}>
    {resultArray.map((card, index) => (
      <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
        <Box
          sx={{
            p: 4,
            position: "relative",
            borderRadius: "5px 5px 0px 0px",
            background: `${ColorArray[(index + 2) % 4].bgColor}`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              marginLeft: "-120px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: card.label === "User" ? "black" : "white",
                fontWeight:
                  card.label === "User" ? "bold" : "extrabold",
              }}
            >
              {card.count}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: card.label === "User" ? "black" : "white",
                fontWeight: "medium",
                fontFamily: "press",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                paddingLeft: "120px",
              }}
            >
              {card.label}
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              py: 3,
              color: card.label === "User" ? "gray.400" : "gray.300",
              opacity: 0.5,
              px: 2,
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.1)",
                duration: 500,
              },
            }}
          >
            {card.label === "Clients" ? (
              <BiUserPlus size={80} />
            ) : (
              <TbBuildingWarehouse size={80} color="#fff" />
            )}
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 1,
            gap: 2,
            color: card.label === "User" ? "black" : "white",
            background: `${ColorArray[(index+2) % 4].darkBg}`,
            borderRadius: "0 0 4px 4px",
            cursor: "pointer",
          }}
        ></Box>
      </Grid>
    ))}
  </Grid>
</Box>
</Box> */}
          <Divider sx={{ borderTop: "2px solid" }} />

          <div component={Paper} sx={{ mt: 5 }}>
            <Divider sx={{ mt: 5 }}>
              <Chip
                label="Todays Activity"
                variant="outlined"
                sx={{ fontSize: "16px" }}
                style={{ fontWeight: "bold" }}
              />
            </Divider>
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="profile" title="Today's Report">
                <InstrumentMaster />
              </Tab>
              <Tab eventKey="master" title="Instrument In.Out">
                <TodaysStdInOut />
              </Tab>
              <Tab eventKey="todaysMaster" title="Today Dues">
                <TodaysSceduler />
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
}
