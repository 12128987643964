import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { ClassicTable } from "./Styles";

const readingColumns = [
  {
    field: "id",
    headerName: "Sr. No.",
    editable: false,
  },
  {
    field: "contactPersonName",
    headerName: "Contact Person Name",
    editable: true,
  },

  {
    field: "contactNo",
    headerName: "Contact No",
    editable: true,
    validate: (value) => /^\d{10}$/.test(value),
    errorText: "(10 digits required)",
  },
  {
    field: "email",
    headerName: "Email Id",
    editable: true,
    validate: (value) =>
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value),
    errorText: "Invalid email address",
  },
];

const EditVendorMaster = () => {
  const [inputStates, setInputStates] = React.useState({});
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const [contactDetailsRows, setContactDetailsRows] = React.useState([
    ["", "", ""],
  ]);
  const [contactDetailsData, setContactDetailsData] = React.useState([]);
  const params = useParams();

  console.log("contactDetailsRows", contactDetailsRows);

  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  const handleSubmit = () => {
    const contactRows = contactDetailsRows.map((row) => ({
      contactPersonName: row[0] || "",
      contactNo: row[1] || "",
      email: row[2] || "",
    }));

    const updatedPayload = {
      ...inputStates,
      startDate: moment(inputStates.startDate).format("YYYY-MM-DD"),
      endDate: moment(inputStates.endDate).format("YYYY-MM-DD"),
      contactDetails:
        contactRows.length > 0 ? JSON.stringify(contactRows) : null,
    };

    let url = BASE_URL;
    if (params.id) {
      axiosWithToken
        .patch(url + `vendor_master/${params.id}`, updatedPayload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Vendor edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axiosWithToken
        .post(url + "vendor_master", inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Vendor created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const fetchVendor_master = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `vendor_master/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        // Parse `contactDetails` separately
        const contactDetailsArray = dataObject?.contactDetails
          ? JSON.parse(dataObject.contactDetails)
          : [];

        // Update state for `contactDetailsData`
        setContactDetailsData(contactDetailsArray);

        // Merge remaining fields into `inputStates`
        let { contactDetails, ...remainingData } = dataObject || {}; // Exclude contactDetails
        let newData = {
          ...inputStates,
          ...remainingData,
        };

        // Update `inputStates` only if `dataObject` exists
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const handleFileUpload = (event, fieldName, q) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);

    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.status === 200) {
          // Assuming `q` is a constant string specific to the field
          updateInputObject(fieldName, res.data, q);
          toast.success("File Uploaded Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something Went Wrong!");
      });
  };

  const addRangeRow = () => {
    setContactDetailsRows((prevRows) => [...prevRows, ["", "", ""]]);
  };

  const updateCellValue = (rowIndex, cellIndex, value) => {
    setContactDetailsRows((prevRows) =>
      prevRows.map((row, i) =>
        i === rowIndex
          ? row.map((cell, j) => (j === cellIndex ? value : cell))
          : row
      )
    );
  };

  const deleteReadingRow = (index) => {
    setContactDetailsRows((prevRows) => prevRows.filter((_, i) => i !== index));
  };

  const convertContactDetailsToRows = () => {
    let rows = [];

    if (contactDetailsData && contactDetailsData.length > 0) {
      for (let i = 0; i < contactDetailsData.length; i++) {
        rows.push([
          contactDetailsData[i].contactPersonName,
          contactDetailsData[i].contactNo,
          contactDetailsData[i].email,
        ]);
      }
    }

    setContactDetailsRows(rows);
  };

  useEffect(() => {
    convertContactDetailsToRows();
  }, [contactDetailsData]);

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) fetchVendor_master();
  }, [params.id]);

  return (
    <div>
      <div>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Create/Update Vendor Master
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Vendor Name  *"
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                value={inputStates.name || ""}
                onChange={(e) => {
                  updateInputObject("name", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Address *"
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                value={inputStates.address || ""}
                onChange={(e) => {
                  updateInputObject("address", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label=" Accreditation Certificate Number *"
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                value={inputStates.certificateNumber || ""}
                onChange={(e) => {
                  updateInputObject("certificateNumber", e.target.value);
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={3} lg={3}>
        <TextField
          id="outlined-basic"
          label="Scope *"
          size="small"
          fullWidth
          variant="outlined"
          value={inputStates.scope || ""}
          onChange={(e) => {
            updateInputObject("scope", e.target.value);
          }}
        />
      </Grid> */}

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="Start Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    inputStates.startDate
                      ? new Date(inputStates.startDate)
                      : null
                  }
                  onChange={(newValue) => {
                    updateInputObject(
                      "startDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="End Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    inputStates.endDate ? new Date(inputStates.endDate) : null
                  }
                  onChange={(newValue) => {
                    updateInputObject(
                      "endDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={4.5} lg={4.5} textAlign={"center"}>
              <Input
                id="file-upload-input1"
                type="file"
                style={{ display: "none" }}
                onChange={(e) =>
                  handleFileUpload(e, "certificateFilePath", "dynamicQValue1")
                }
              />
              <label htmlFor="file-upload-input1">
                <Button variant="contained" component="span">
                  Upload Accreditation Certificate
                </Button>
              </label>
              <Typography variant="body2" color="textSecondary">
                Select a file to upload
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4.5} lg={4.5} textAlign={"center"}>
              <Input
                id="file-upload-input"
                type="file"
                style={{ display: "none" }}
                onChange={(e) =>
                  handleFileUpload(e, "scopePath", "dynamicQValue2")
                }
              />
              <label htmlFor="file-upload-input">
                <Button variant="contained" component="span">
                  Upload Accreditation Scope
                </Button>
              </label>
              <Typography variant="body2" color="textSecondary">
                Select a file to upload
              </Typography>
            </Grid>
          </Grid>

          <br />
          <hr />

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              style={{ float: "left", margin: "20px 0px" }}
            >
              Contact Details
            </Typography>
            <Button
              id="client_edit_addcontact"
              variant="contained"
              size="small"
              onClick={addRangeRow}
            >
              Add Contact
            </Button>
          </Box>

          <div style={{ width: "100%", overflow: "auto" }}>
            <ClassicTable>
              <Table sx={{ minWidth: 660 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {readingColumns?.map((column, index) => (
                      <TableCell key={column.field || index}>
                        <Typography noWrap>{column.headerName}</Typography>
                      </TableCell>
                    ))}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contactDetailsRows.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell>{rowIndex + 1}</TableCell>
                      {row.map((cell, cellIndex) => (
                        <TableCell key={cellIndex}>
                          <TextField
                            id="client_edit_contvalue"
                            size="small"
                            inputProps={{
                              autoComplete: "off",
                            }}
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(
                                rowIndex,
                                cellIndex,
                                e.target.value
                              );
                            }}
                            type={cellIndex === 2 ? "text" : "text"}
                            inputMode={cellIndex === 1 ? "text" : "numeric"}
                            error={
                              readingColumns[cellIndex + 1]?.validate &&
                              !readingColumns[cellIndex + 1]?.validate(cell)
                            }
                            helperText={
                              readingColumns[cellIndex + 1]?.errorText &&
                              !readingColumns[cellIndex + 1]?.validate(cell)
                                ? readingColumns[cellIndex + 1].errorText
                                : ""
                            }
                          />
                        </TableCell>
                      ))}
                      <TableCell>
                        <DeleteIcon
                          id="client_edit_contdelete"
                          style={{ color: "#dc3545", cursor: "pointer" }}
                          onClick={() => deleteReadingRow(rowIndex)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ClassicTable>
          </div>
          <br />

          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
              size="small"
              sx={{ m: 0, ml: "auto", px: 4 }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
        </Paper>
      </div>
    </div>
  );
};

export default EditVendorMaster;
