import React from "react";
import "./user.css";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoIcon from "@mui/icons-material/Info";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

export const userTypes = [
  {
    id: 1,
    label: "Admin",
  },
  {
    id: 2,
    label: "Plant Head",
  },
  {
    id: 4,
    label: "Department Head",
  },
  {
    id: 5,
    label: "Supervisor",
  },
  {
    id: 6,
    label: "Incharge",
  },
  {
    id: 3,
    label: "Vendor",
  },
];

const moduleList = [
  {
    id: 1,
    name: "Dashboard",
  },
  {
    id: 2,
    name: "Instrument",
  },
  {
    id: 3,
    name: "Instrument Master",
  },
  {
    id: 4,
    name: "Plant Master",
  },
  {
    id: 5,
    name: "Lab Master",
  },
  {
    id: 6,
    name: "Vendor Master",
  },
  {
    id: 7,
    name: "Calibration Register",
  },
  {
    id: 8,
    name: "Instrument Status",
  },
  {
    id: 9,
    name: "Scheduler",
  },
  {
    id: 10,
    name: "History",
  },
  {
    id: 11,
    name: "Summary",
  },
  {
    id: 12,
    name: "Units",
  },
  {
    id: 13,
    name: "Users",
  },
  {
    id: 14,
    name: "Instrument InOut List",
  },
  {
    id: 15,
    name: "Allocated Report",
  },
  {
    id: 16,
    name: "Instrument In/Out",
  },
  {
    id: 17,
    name: "Reports",
  },
];

export const editAccessOptions = [
  {
    id: 0,
    label: "All Access",
  },
  {
    id: 1,
    label: "Edit",
  },
  {
    id: 2,
    label: "Delete",
  },
  {
    id: 3,
    label: "PDF Download",
  },
  {
    id: 4,
    label: "Excel Download",
  },
  {
    id: 5,
    label: "Copy Data",
  },
  {
    id: 6,
    label: "None",
  },
];

export default function EditUser() {
  const { id } = useParams();
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passVal, setPasswordVal] = React.useState([0, 0, 0, 0, 0, 0]);
  const [roles, setRoles] = React.useState("");
  const [executing, setExecuting] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [modules, setModules] = React.useState(moduleList);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [type, setType] = React.useState({});
  const [typeId, setTypeID] = React.useState(0);
  const [userEmail, setUserEmail] = React.useState("");
  const [contactNo, setContactNo] = React.useState("");
  const [vendorData, setVendorData] = React.useState([]);
  const [vendorID, setVendorID] = React.useState(0);
  const [editAccess, setEditAccess] = React.useState([]);
  const [editUserAccess, setEditUserAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const [emailError, setEmailError] = React.useState(false);
  const [contactError, setContactError] = React.useState(false);
  const [plantData, setPlantData] = React.useState([]);
  const [plantIds, setPlantIds] = React.useState(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  console.log("typeId", typeId);

  const onSubmit = async () => {
    setExecuting(true);
    var jsonData = {
      userName: userName,
      password: password,
      email: userEmail,
      plantId: plantIds,
      contactNo: contactNo,
      editAccess: editAccess.join(",") || "0",
      vendorId: vendorID,
      modules:
        roles?.id !== 1
          ? (selectedModules || [])?.map((m) => m.id).join(",")
          : null,
      type: typeId,
    };

    let ret = id
      ? axiosWithToken.patch(BASE_URL + `users/${id}`, jsonData)
      : axiosWithToken.post(BASE_URL + `users`, jsonData);
    ret
      .then((res) => {
        id
          ? toast.success("Updated Sucessfully!")
          : toast.success("User Create Sucessfully!");
        refresh();
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  async function fetchUserDetails() {
    await axiosWithToken.get(BASE_URL + `users/${id}`).then((res) => {
      setUserName(res.data[0].userName);
      setPlantIds(res.data[0].plantId);
      setPassword(res.data[0].password);
      // setType(res.data[0].type);
      setTypeID(res.data[0].type);
      setUserEmail(res.data[0].email);
      setContactNo(res.data[0].contactNo);
      let AccessId = res.data[0].editAccess?.split(",")?.map(Number);
      setEditAccess(AccessId);
      setVendorID(res.data[0].vendorId);

      if (res.data[0].password) {
        let vals = passVal;

        let lowerCaseLetters = /[a-z]/g;
        res.data[0].password.match(lowerCaseLetters)
          ? (vals[0] = 1)
          : (vals[0] = 0);

        // Validate capital letters
        let upperCaseLetters = /[A-Z]/g;
        res.data[0].password.match(upperCaseLetters)
          ? (vals[1] = 1)
          : (vals[1] = 0);

        // Validate numbers
        let numbers = /[0-9]/g;
        res.data[0].password.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

        // Validate length
        res.data[0].password.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
        res.data[0].password.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

        setPasswordVal(vals);
      }
      res.data[0].modules
        ? setSelectedModules(
            res.data[0].modules?.split(",").map((m) => moduleList[m - 1] || [])
          )
        : setSelectedModules([]);
    });
  }

  const validateData = () => {
    let isValid = true;

    if (!userName) {
      toast.error("Please Enter The User Name");
      isValid = false;
    }

    // Inline email validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)) {
      toast.error("Please enter a valid email address");
      isValid = false;
    }

    // Inline contact number validation
    if (!/^[0-9]{10}$/.test(contactNo)) {
      toast.error("Please enter a valid 10-digit contact number");
      isValid = false;
    }

    if (!typeId > 0) {
      toast.error("Please select user type");
      isValid = false;
    }

    return isValid;
  };

  const fetchVendor_master = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `vendor_master?_fields=id,name`)
      .then((res) => {
        let dataObject = res.data;
        setVendorData(dataObject);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  function fetchPlantData() {
    axiosWithToken
      .get(BASE_URL + `plant_master`)
      .then((res) => {
        setPlantData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  React.useEffect(() => {
    if (id) {
      fetchUserDetails();
    }
  }, [id]);

  React.useEffect(() => {
    fetchVendor_master();
    fetchPlantData();
  }, []);

  return (
    <Paper sx={{ px: 2 }}>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12} md={3}>
          <TextField
            id="outlined-basic"
            label="Username *"
            value={userName}
            size="small"
            fullWidth
            inputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="outlined-basic"
            label="Password *"
            value={password}
            size="small"
            fullWidth
            inputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            onChange={(e) => {
              let vals = passVal;

              let lowerCaseLetters = /[a-z]/g;
              e.target.value.match(lowerCaseLetters)
                ? (vals[0] = 1)
                : (vals[0] = 0);

              // Validate capital letters
              let upperCaseLetters = /[A-Z]/g;
              e.target.value.match(upperCaseLetters)
                ? (vals[1] = 1)
                : (vals[1] = 0);

              // Validate numbers
              let numbers = /[0-9]/g;
              e.target.value.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

              // Validate length
              e.target.value.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
              e.target.value.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

              setPasswordVal(vals);
              setPassword(e.target.value);
            }}
            style={{ position: "relative" }}
          />
          <InfoIcon
            style={{ color: "gray", position: "absolute", height: "20px" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          {isHovered && (
            <div>
              <p id="letter" className={passVal[0] ? "valid" : "invalid"}>
                A <b>lowercase</b> letter
              </p>
              <p id="capital" className={passVal[1] ? "valid" : "invalid"}>
                A <b>capital (uppercase)</b> letter
              </p>
              <p id="number" className={passVal[2] ? "valid" : "invalid"}>
                A <b>number</b>
              </p>
              <p id="lengthMin" className={passVal[3] ? "valid" : "invalid"}>
                Minimum <b>8 characters</b>
              </p>
              <p id="lengthMax" className={passVal[4] ? "valid" : "invalid"}>
                Maximum <b>32 characters</b>
              </p>
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            id="outlined-basic-email"
            label="Email *"
            type="email"
            value={userEmail}
            size="small"
            fullWidth
            inputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            error={emailError}
            helperText={emailError ? "Invalid email address" : ""}
            onChange={(e) => {
              const email = e.target.value;
              setUserEmail(email);
              setEmailError(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            id="outlined-basic-contact"
            label="Contact Number *"
            type="tel"
            value={contactNo}
            size="small"
            fullWidth
            inputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            error={contactError}
            helperText={contactError ? "Invalid contact number" : ""}
            onChange={(e) => {
              const contact = e.target.value;
              setContactNo(contact);
              setContactError(!/^[0-9]{10}$/.test(contact));
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
      <Grid item xs={12} md={3}>
        <Autocomplete
          size="small"
          id="combo-box-demo"
          multiple
          options={plantData}
          disableCloseOnSelect
          getOptionLabel={(option) => option.location}
          value={plantData?.filter((plant) =>
            plantIds?.split(",")?.map(Number)?.includes(plant.id)
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                style={{ marginRight: 8 }}
                checked={plantIds?.split(",")?.map(Number)?.includes(option.id)}
              />
              {option.location}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Plant Location" />
          )}
          onChange={(event, value) => {
            const selectedIds = value?.map((val) => val.id)?.join(",");
            setPlantIds(selectedIds);
          }}
        />
    </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            key={typeId}
            size="small"
            id="combo-box-demo"
            options={userTypes}
            getOptionLabel={(option) => option.label || ""}
            value={userTypes?.find((ele) => ele.id == typeId)}
            renderInput={(params) => (
              <TextField {...params} label="User Types *" />
            )}
            onChange={(e, val) => {
              setType(val);
              setTypeID(val?.id);
            }}
          />
        </Grid>

        {typeId == 3 && (
          <>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Autocomplete
                size="small"
                options={vendorData}
                value={
                  vendorData?.filter((ele) => ele?.id == vendorID)?.[0] || null
                }
                getOptionLabel={(option) => `${option.id}, ${option.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a vendor"
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => {
                  setVendorID(value?.id);
                }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3}>
          <Autocomplete
            multiple
            fullWidth
            id="modules"
            size="small"
            options={modules || []}
            defaultValue={undefined}
            value={selectedModules || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />

                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select modules (optional)"
                placeholder="select multiple modules"
              />
            )}
            onChange={(event, value) => setSelectedModules(value || [])}
          />
        </Grid>

        {typeId !== 3 && (
          <>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Autocomplete
                multiple
                size="small"
                options={editAccessOptions}
                getOptionLabel={(option) => option.label}
                value={editAccessOptions.filter((option) =>
                  editAccess?.includes(option.id)
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Edit Access" />
                )}
                onChange={(event, newValue) => {
                  setEditAccess(newValue.map((option) => option.id));
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          style={{ backgroundColor: "grey" }}
          variant="contained"
          size="small"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 2 }}
          disabled={
            !(editUserAccess == 1 || editUserAccess == 0) ||
            executing ||
            userName === "" ||
            password === "" ||
            passVal.reduce((s, v) => s + v, 0) < 5 ||
            (!id && !type?.label)
          }
          onClick={() => {
            if (!validateData()) return;
            onSubmit();
          }}
        >
          {`${id ? "Update" : "Create"} ${
            type && type?.label ? `${type?.label} : ` : ""
          } ${userName}`}
        </Button>
      </Toolbar>
    </Paper>
  );
}
