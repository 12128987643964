import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import MultiValueInputModal from "../../utils/components/multiValueInputModal";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ImageWithTable from "../../utils/components/imageWithTable";

const modalConfigs = [
  {
    buttonLabel: "Range",
    transformedStringLabel: "instrumentRange",
  },
  {
    buttonLabel: "Least Count",
    transformedStringLabel: "leastCount",
  },
  {
    buttonLabel: "Accuracy",
    transformedStringLabel: "accuracy",
  },
];

const calArray = [
  { id: 1, label: "3 Months" },
  { id: 2, label: "6 Months" },
  { id: 3, label: "12 Months" },
  { id: 3, label: "18 Months" },
  { id: 4, label: "24 Months" },
];

const EditinstrumentMaster = () => {
  const [inputStates, setInputStates] = React.useState({ instrumentId: null });
  const [labList, setLabList] = React.useState([]);
  const [instrumentList, setInstrumentList] = React.useState([]);
  const [plantData, setPlantData] = React.useState([]);
  const [vendorMasterData, setVendorMasterData] = React.useState([]);
  const [coOrdinatorData, setCoOrdinatorData] = React.useState([]);
  const params = useParams();
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const userType = localStorage.getItem("type");
  const _vendorId = localStorage.getItem("vendorId");

  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  const handleSubmit = () => {
    const url = `${BASE_URL}instrument_master/`;
    const formatDate = (date) =>
      date ? moment(date).format("YYYY-MM-DD") : null;

    // Calculate and format dueDate
    if (inputStates.startDate && inputStates.calibrationFrequency) {
      const [freqValue] = inputStates.calibrationFrequency.split(" ");
      inputStates.dueDate = moment(inputStates.startDate)
        .add(Number(freqValue), "M")
        .format("YYYY-MM-DD");
    } else {
      inputStates.dueDate = null;
    }

    inputStates.startDate = formatDate(inputStates.startDate);

    const axiosMethod = params.id ? axiosWithToken.patch : axiosWithToken.post;
    const requestUrl = params.id ? `${url}${params.id}` : url;

    axiosMethod(requestUrl, inputStates)
      .then(() => {
        setTimeout(refresh, 500);
        toast(`Instrument ${params.id ? "edit" : "created"} successfully!`);
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };

  const fetchInstrument_master = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instrument_master/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        // Split the values of workSopDoc and instrumentImages if they exist
        if (dataObject.workSopDoc) {
          dataObject.workSopDoc = dataObject.workSopDoc?.split(",") || [];
        }

        if (dataObject.instrumentImages) {
          dataObject.instrumentImages =
            dataObject.instrumentImages?.split(",") || [];
        }

        // Merge the dataObject with inputStates
        let newData = {
          ...inputStates,
          ...dataObject,
        };

        // Update the state if dataObject exists
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  function getLABData() {
    axiosWithToken
      .get(BASE_URL + `lab_master`)
      .then((res) => {
        let newData = res.data;
        setLabList(newData);
      })
      .catch((err) => {
        console.log("lab_master data fetching error: ", err);
      });
  }

  function getInstrumentData() {
    axiosWithToken
      .get(BASE_URL + `instrument`)
      .then((res) => {
        let newData = res.data;
        setInstrumentList(newData);
      })
      .catch((err) => {
        console.log("Instrument data fetching error: ", err);
      });
  }

  function fetchPlantData() {
    axiosWithToken
      .get(BASE_URL + `plant_master`)
      .then((res) => {
        setPlantData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const validateData = () => {
    let isValid = true;

    if (!inputStates?.instrumentId) {
      toast.error("Please select a Instrument");
      isValid = false;
    }
    // if (!inputStates?.serialNumber) {
    //   toast.error("Please  Enter Serial Number");
    //   isValid = false;
    // }
    if (!inputStates?.identificationNumber) {
      toast.error("Please  Enter Identification Number");
      isValid = false;
    }
    // if (!inputStates?.modelNo) {
    //   toast.error("Please  Enter Model Number");
    //   isValid = false;
    // }
    if (!inputStates?.instrumentRange) {
      toast.error("Please  Add Instrument Range");
      isValid = false;
    }

    return isValid;
  };

  function getVendorMasterData() {
    axiosWithToken
      .get(BASE_URL + `vendor_master`)
      .then((res) => {
        let newData = res.data;
        setVendorMasterData(newData);
      })
      .catch((err) => {
        console.log("instrument data fetching error: ", err);
      });
  }

  function getCoOrdinatorData() {
    axiosWithToken
      .post(BASE_URL + `dynamic`, {
        query: "SELECT userName, id FROM users WHERE type = 6",
      })
      .then((res) => {
        let newData = res.data;
        setCoOrdinatorData(newData);
      })
      .catch((err) => {
        console.log("CoOrdinator data fetching error: ", err);
      });
  }

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) fetchInstrument_master();
  }, [params.id]);

  useEffect(() => {
    getLABData();
    getInstrumentData();
    fetchPlantData();
    getVendorMasterData();
    getCoOrdinatorData();
  }, []);

  return (
    <div>
      <div>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Create/Update Instrument Master
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={instrumentList}
                disabled={userType == 3}
                getOptionLabel={(option) => option.name}
                value={
                  instrumentList.find(
                    (lab) => lab.id === inputStates.instrumentId
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Instrument Name *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("instrumentId", value.id);
                    // updateInputObject("name", value.name);
                  } else {
                    updateInputObject("instrumentId", "");
                    // updateInputObject("name", "");
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Serial Number *"
                size="small"
                value={inputStates.serialNumber || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("serialNumber", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Make "
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                value={inputStates.make || ""}
                onChange={(e) => {
                  updateInputObject("make", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Identification Number *"
                size="small"
                value={inputStates.identificationNumber || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("identificationNumber", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Model Number *"
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                // rows={3}
                // multiline
                // maxRows={5}
                value={inputStates.modelNo ? inputStates.modelNo : ""}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("modelNo", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Block Number"
                size="small"
                value={inputStates.blockNo || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("blockNo", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Attached Instrument Name"
                size="small"
                value={inputStates.attachedInstrumentName || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("attachedInstrumentName", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Floor Name"
                size="small"
                value={inputStates.floorName || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("floorName", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Location of Equipment's "
                size="small"
                value={inputStates.location || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("location", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  label="Start Date *"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={
                    inputStates.startDate
                      ? new Date(inputStates.startDate)
                      : null
                  }
                  onChange={(newValue) => {
                    updateInputObject(
                      "startDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                //   disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={calArray}
                getOptionLabel={(option) => option.label}
                value={
                  calArray.find(
                    (lab) => lab.label === inputStates.calibrationFrequency
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Calibration Frequency  *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("calibrationFrequency", value.label);
                  } else {
                    updateInputObject("calibrationFrequency", "");
                  }
                }}
              />
            </Grid>

            {/* <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={labList}
                getOptionLabel={(option) => option.name}
                value={
                  labList.find((lab) => lab.id === inputStates.labId) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Lab Name *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("labId", value.id);
                  } else {
                    updateInputObject("labId", "");
                  }
                }}
              />
            </Grid> */}

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Test points "
                size="small"
                value={inputStates.testPoints || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("testPoints", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label="Permissible Error"
                size="small"
                value={inputStates.permissibleError || ""}
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("permissibleError", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                size="small"
                id="combo-box-multiselect"
                options={
                  userType == 6
                    ? coOrdinatorData?.filter(
                        (vendor) => vendor.id == _vendorId
                      )
                    : coOrdinatorData
                }
                getOptionLabel={(option) => option.userName}
                value={coOrdinatorData.filter(
                  (option) =>
                    inputStates.inChargeIds
                      ?.split(",")
                      .map((id) => Number(id)) // Convert to numbers
                      .includes(option.id) // Compare with option.id (already a number)
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} style={{ marginRight: 8 }} />
                    {option.userName}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Incharge Name" />
                )}
                onChange={(event, value) => {
                  const selectedIds = value
                    .map((option) => option.id)
                    .join(",");
                  updateInputObject("inChargeIds", selectedIds);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                size="small"
                id="calibration-type-autocomplete"
                options={["InHouse", "OutSource"]}
                getOptionLabel={(option) => option}
                value={inputStates.calibrationType || null}
                renderInput={(params) => (
                  <TextField {...params} label="Calibration Type" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("calibrationType", value);
                  } else {
                    updateInputObject("calibrationType", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={plantData}
                getOptionLabel={(option) => option.location}
                value={
                  plantData.find((lab) => lab.id === inputStates.plantId) ||
                  null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Plant Location " />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("plantId", value.id);
                    // updateInputObject("name", value.name);
                  } else {
                    updateInputObject("plantId", "");
                    // updateInputObject("name", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disabled={params.invoiceId}
                size="small"
                id="combo-box-demo"
                options={
                  userType == 3
                    ? vendorMasterData?.filter(
                        (vendor) => vendor.id == _vendorId
                      )
                    : vendorMasterData
                }
                getOptionLabel={(option) => option.name}
                value={
                  vendorMasterData.find(
                    (lab) => lab.id === inputStates.assignedUser
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Vendor Name " />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("assignedUser", value.id);
                  } else {
                    updateInputObject("assignedUser", "");
                  }
                }}
              />
            </Grid>

            <Grid item spacing={3}>
              <div style={{ display: "flex", gap: "50px", flexWrap: "wrap" }}>
                {modalConfigs.map((config, index) => (
                  <MultiValueInputModal
                    key={index}
                    buttonLabel={config.buttonLabel}
                    transformedStringLabel={config.transformedStringLabel}
                    inputStates={inputStates}
                    updateInputObject={updateInputObject}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
          <br/>
          <hr/>
          <br/>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ImageWithTable
                inputStates={inputStates}
                setInputStates={setInputStates}
                label="Work Instruction PDF Upload"
                stateKey="workSopDoc"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ImageWithTable
                inputStates={inputStates}
                setInputStates={setInputStates}
                label="Instrument Image"
                stateKey="instrumentImages"
              />
            </Grid>
          </Grid>
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
              sx={{ m: 0, ml: "auto", px: 4 }}
              onClick={() => {
                if (!validateData()) return;
                handleSubmit();
              }}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
        </Paper>
      </div>
    </div>
  );
};

export default EditinstrumentMaster;
