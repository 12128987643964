import { Autocomplete, Button, Grid, Paper, TextField, Toolbar, Typography } from '@mui/material'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from 'react'
import { BASE_URL } from "../../global";
import axiosWithToken from '../../utils/components/axiosTokenConfig';

const EditInstrument = () => {
    const [inputStates, setInputStates] = React.useState({});
    const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
    const params = useParams();
  
    const updateInputObject = (key, value, q) => {
        let newInputObject = {
          ...inputStates,
        };    
        newInputObject[key] = value;    
        setInputStates({ ...newInputObject });
      };
  
      const handleSubmit =()=>{
        const { lastModified, ...payload } = inputStates
        let url = BASE_URL;
        if (params.id) {
          axiosWithToken
            .patch(url + `instrument/${params.id}`, payload)
            .then((res) => {
              setTimeout(refresh, 500);
              toast("Instrument edit successfully !");
            })
            .catch((error) => {
              toast.error("Something Went Wrong!");
            });
    
        } else {
          axiosWithToken
            .post(url + "instrument", payload)
            .then((res) => {
              setTimeout(refresh, 500);
              toast("Instrument created successfully !");
            })
            .catch((error) => {
              toast.error("Something Went Wrong!");
            });
    
        }
  
      }
  
      const fetchinstrument = (inpt) => {
        let url = BASE_URL;
        axiosWithToken
          .get(url + `instrument/${params.id}`)
          .then((res) => {
            let dataObject = res.data[0];
    
            let newData = {
              ...inputStates,
              ...dataObject,
             
            };
              dataObject && setInputStates(newData);
           
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
      };
  
      var refresh = () => {
        window.location.reload(false);
      };

      const validateData = () => {
        let isValid = true;
    
        if (!inputStates?.name) {
          toast.error("Please Enter a Instrument Name");
          isValid = false;
        }
        
    
        return isValid;
      };
  
  useEffect(()=>{
   if(params.id)fetchinstrument()
  },[params.id])
  
  return (
    <div>
            <div>
     <Paper sx={{ mt: 2, p: 2 }}>
     <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create/Update Instrument
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
       
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            id="outlined-basic"
            label="Instrument Name  *"
            size="small"
            fullWidth
            inputProps={{
              autoComplete: 'off',
            }}
            variant="outlined"
            value={inputStates.name || ""}
            onChange={(e) => {
              updateInputObject("name", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
      <Button
            variant="contained"
            disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
            size="small"
            sx={{ m: 0, ml: "auto", px: 4 }}
            onClick={() => {
              if (!validateData()) return;
                 handleSubmit()             
            }}
          >
        {params.id ? "Update":"Save"}
          </Button>
      </Toolbar>
     </Paper>
    </div>
    </div>
  )
}

export default EditInstrument